import { get_service_endpoint } from 'ServiceEndpoints';
import { flash } from './CommonActions';
import axios from 'axios';
import { createAction } from 'redux-actions';

const GET_ACTIVE_ISSUE_DATA = createAction('GET_ACTIVE_ISSUE_DATA');
const GET_PAGINATION_DATA = createAction('GET_PAGINATION_DATA');
const GET_HISTORY_ISSUE_DATA = createAction('GET_HISTORY_ISSUE_DATA');
const fabric = get_service_endpoint('fabric');

export const getActiveIssueData = (params) => async (dispatch) => {
  try {
    const res = await axios.get(`${fabric}/orders/raise-issue-list`, { params, isSession: true });
    dispatch(GET_ACTIVE_ISSUE_DATA(res.data.data.data)); // if data is coming from staging api use this
    dispatch(
      GET_PAGINATION_DATA({ perPageLimit: res.data.data.limit, totalEntries: res.data.data.total }),
    );
  } catch (err) {
    dispatch(
      flash({
        message: err.msg,
      }),
    );
  }
};
