import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Modal, Table, Select, Input, } from 'antd';
import { Formik, Form } from 'formik';
import { debounce } from 'lodash';
import {
  INVENTORY_TABS as IT,
  CUSTOMER_ISSUE_TABS as CIT,
  activeCustomerIssuesColumns,
  customerIssuesHistoryColumns,
} from './../base';
import { connect } from 'react-redux';
import ProductImg from './../../../../assets/img/Picture.svg';
import LeftChevron from './../../../../assets/images/navigation/ic-chevron-left-blue.svg';
import LfbIdDetails from '../lfbIdDetails/lfbIdDetails';
import { getActiveIssueData } from 'store/actions/IssuePanelActions';
import { get_service_endpoint } from 'ServiceEndpoints';
import history from 'utilites/history';

const fabric = get_service_endpoint('fabric');
const { Option } = Select;
const SEARCH_FILTERS = [
  { filterText: 'customerName', filterName: 'Customer Name' },
  { filterText: 'generatedFabricId', filterName: 'LFB-ID' },
];
const DEFAULT_DATA_LIMIT = 10;

const getIssuePanelPage = () => {
  const pageNum = sessionStorage.getItem('issuePanelPage');
  if(Boolean(pageNum)) return Number(pageNum);
  return 1;
}

const CustomerIssues = ({
  getActiveIssueData,
  activeIssueData,
  paginationData,
}) => {
  const [tab, setTab] = useState(CIT.CUSTOMER_ISSUES);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('customerName');
  const [searchColumn, setSearchColumn] = useState('name');
  const [showFileteredData, setShowFileteredData] = useState(false);
  const [showFileteredSuggestion, setShowFilteredSuggestion] = useState(false);
  const [sku, setSKU] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [viewImageModal, setViewImageModal] = useState(false);
  const [page, setPage] = useState(() => getIssuePanelPage());
  const [loader, setLoader] = useState(false);

  const handleTabChange = (newTab) => {
    setTab(newTab);
    sessionStorage.setItem('selectedTab', newTab);
    if(!showFileteredData) setSearchTerm('');
    reloader();
  };
  const reloader = () => {
    setLoader(!loader);
  };
  const filteredData = activeIssueData.filter((item) => {
    const searchTermString = String(searchTerm || '').toLowerCase();
    return searchType === 'customerName'
      ? item['customerName']?.toLowerCase().includes(searchTermString)
      : (item['generatedFabricId'] + '').includes(searchTermString);
  });

  useEffect(() => {
    let params = {
      page: page,
      limit: DEFAULT_DATA_LIMIT,
    };
    getActiveIssueData(params);
  }, [page, loader]);

  let filteredSuggestions = activeIssueData.filter((item) =>
    (item[searchType] + '')?.toLowerCase().includes((searchTerm + '').toLowerCase()),
  );
  filteredSuggestions = [...new Set(filteredSuggestions)];
  
  return (
  <div className={styles.page} styles={{ display: 'flex' }}>
      <div className={styles.head}>
        <div className={styles.headline}>
          <div className={styles['headline-wrapper']}>
            <span
              className={styles['back-button-chevron']}
              onClick={() => history.goBack()}>
              <img src={LeftChevron} alt="" />
            </span>
            <span>Customer Issues</span>
          </div>
        </div>
      </div>

      <div className="flex  justify-between items-center mt-4">
        <div className={styles['button-switch-main']} style={{ display: 'flex' }}>
          <div
            onClick={() => handleTabChange(CIT.CUSTOMER_ISSUES)}
            className={
              styles[tab === CIT.CUSTOMER_ISSUES ? 'btn-selected-main' : 'btn-unselected-main']
            }>
            Customer Issues
          </div>
          <div
            onClick={() => handleTabChange(CIT.HISTORY)}
            className={styles[tab === CIT.HISTORY ? 'btn-selected-main' : 'btn-unselected-main']}>
            History
          </div>
        </div>

        <div
          className="flex justify-center items-center gap-4 searchBox"
          style={{ position: 'relative' }}>
          <p style={{ marginRight: 10 }} className="grey-text">
            Search by:
          </p>
          <Formik
            enableReinitialize
            initialValues={{ searchFilter: 'customerName', searchText: '' }}>
            <Form style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <Select
                value={searchType}
                onChange={(value) => {
                  setSearchType(value);
                  setShowFilteredSuggestion(true);
                }}
                style={{ width: 150, marginRight: 10, fontWeight: 700 }}>
                {SEARCH_FILTERS.map((filter) => (
                  <Option key={filter.filterText} value={filter.filterText}>
                    {filter.filterName}
                  </Option>
                ))}
              </Select>
              <Input
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setShowFileteredData(false);
                  setShowFilteredSuggestion(true);
                }}
                style={{ width: 200, height: 35 }}
              />
            </Form>
          </Formik>

          {/* Suggestion Box */}
          {(showFileteredSuggestion && searchTerm) && (
            <div
              className="suggestion-box"
              style={{
                position: 'absolute',
                top: '37px',
                right: '0',
                width: '200px',
                background: 'white',
                border: '1px solid #ccc',
                borderRadius: '5px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                zIndex: 1000,
                maxHeight: '250px',
                overflowY: 'auto',
              }}>
              {(filteredSuggestions.length > 0) ? (
                filteredSuggestions
                  .filter(
                    (value, index, self) =>
                      self.findIndex((v) => v[searchType] === value[searchType]) === index,
                  )
                  .map((item, index) => (
                    <div
                      key={index}
                      className="suggestion-item"
                      style={{ padding: '8px', cursor: 'pointer', borderBottom: '1px solid #ddd' }}
                      onClick={() => {
                        setSearchTerm(item[searchType]);
                        setShowFileteredData(true);
                        setShowFilteredSuggestion(false);
                      }}>
                      {item[searchType]}
                    </div>
                  ))
              ) : (
                <div className="suggestion-item" style={{ padding: '8px', color: 'gray' }}>
                  No results found
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {tab === CIT.CUSTOMER_ISSUES ? (
        <Table
          className={styles.inventoryTable}
          columns={activeCustomerIssuesColumns({
            sku,
            setRecordData,
            setViewImageModal,
            setOrderId,
          })}
          dataSource={showFileteredData ? filteredData : activeIssueData}
          scroll={{
            y: 600, // Set the height to make the header fixed at the top and allow vertical scrolling
          }}
          pagination={{
            current: page,
            position: ['topRight'],
            defaultPageSize: DEFAULT_DATA_LIMIT,
            total: paginationData?.totalEntries,
            size: 'small',
            showSizeChanger: false,
            onChange: (page) => {
              setPage(Number(page));
              sessionStorage.setItem('issuePanelPage', page)
            },
          }}
        />
      ) : (
        <Table
          className={styles.inventoryTable}
          columns={customerIssuesHistoryColumns({ sku, setRecordData, setViewImageModal })}
          dataSource={[]} // passing empty array for history table
          pagination={false}
          scroll={{
            y: 600, // Set the height to make the header fixed at the top and allow vertical scrolling
          }}
        />
      )}
      <Modal
        visible={viewImageModal}
        onCancel={() => setViewImageModal(false)}
        className={styles['img-modal']}
        destroyOnClose
        footer={null}>
        <div>
          <img
            className={styles.imgBoxModal}
            src={recordData?.fabricFileloc ? recordData?.fabricFileloc : ProductImg}
            alt="Greige Fabric"
          />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  activeIssueData: state.issuePanelData.activeIssueData,
  paginationData: state.issuePanelData.paginationData,
});

const mapDispatchToProps = (dispatch) => ({
  getActiveIssueData: (data) => dispatch(getActiveIssueData(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerIssues);
