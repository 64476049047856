import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import './issueDetail.scss';
import infoIcon from './../../../../assets/images/action/ic-info-blue.svg';
import axios from 'axios';
import { get_service_endpoint } from 'ServiceEndpoints';
import pdfIcon from 'assets/PDF.svg';
import AttachmentModal from './AttachmentModal.jsx/AttachmentModal';
const fabric = get_service_endpoint('fabric');

const IssueDetailsModal = ({ value, record }) => {
  const { customerName, totalQuantity, baleCount } = record;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [baleDetails, setBaleDetails] = useState({});
  const [baleList, setBaleList] = useState('');
  const [url, setUrl] = useState(null);
  const [isImage, setIsImage] = useState(false);
  const [attachmentModalVisible, setAttachmentModalVisible] = useState(false);
  const issueTypes = [],
    issueDescriptions = [];
  baleDetails.orderIssueDetails?.map((item, index) => {
    if (item?.description === 'Others') {
      issueDescriptions.push(item.comments);
    } else issueTypes.push(item.description);
  });
  const handleInfoIconClick = () => {
    (async () => {
      const balesData = await axios.get(`${fabric}/orders/${record.orderId}/raise-issue`, {
        isSession: true,
      });
      const baleList = balesData.data.data.raiseIssueBaleDetails.balesWithIssueRaised
        .map((item) => item.baleNumber)
        .join(', ');
      setBaleList(baleList);
      setBaleDetails(balesData.data.data);
    })();
    setIsModalVisible(!isModalVisible);
  };
  return (
    <>
      <div className="issue-details-container">
        <div className="issue-details-content">
          <p>
            <span>
              {totalQuantity} <span className="grey-text bale-count">m</span>
            </span>
            <br />
            <span className="grey-text bale-count">
              {baleCount} {baleCount > 1 ? 'bales' : 'bale'}
            </span>
          </p>
        </div>

        <div className="issue-detail-info-icon-container">
          <img
            onClick={() => handleInfoIconClick()}
            src={infoIcon}
            alt="info icon"
          />
        </div>
      </div>

      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(!isModalVisible)}
        footer={null}
        className="issue-modal">
        <div className="modal-content">
          <h2 className="modal-title">{customerName}</h2>

          {issueTypes.length > 0 && (
            <div className="modal-section">
              <h4 className="modal-label font-wt">Issue type</h4>
              <p className="modal-text shift-up">{issueTypes?.join(', ')}</p>
            </div>
          )}

          <div className="modal-section">
            <h4 className="modal-label font-wt">Quantity</h4>
            <p className="modal-quantity shift-up">
              <span className="quantity-label">{totalQuantity} m</span>
              <span className="bales">
                ({baleCount} {baleCount > 1 ? 'bales' : 'bale'})
              </span>
            </p>
            <p className="modal-text font-wt" style={{ marginTop: '-20px' }}>
              {baleList}
            </p>
          </div>

          {issueDescriptions.length > 0 && (
            <div className="modal-section">
              <h4 className="modal-label font-wt">Issue description</h4>
              <p className="modal-text shift-up">{issueDescriptions?.join(', ')}</p>
            </div>
          )}

          {baleDetails.attachedFiles?.length > 0 ? (
            <div className="modal-attachments">
              {baleDetails.attachedFiles?.map((file) => {
                const fileTypePattern = /\.(pdf|jpg|jpeg|png)$/i;
                const match = file.url.match(fileTypePattern);

                if (file.title === 'Order Issue' && match) {
                  const fileType = match[0].toLowerCase();
                  const isImage =
                    fileType === '.jpg' || fileType === '.jpeg' || fileType === '.png';

                  if (isImage) {
                    return (
                      <img
                        src={file.url}
                        alt="fabric img"
                        className="attachment-image"
                        onClick={() => {
                          setUrl(file.url);
                          setAttachmentModalVisible(!attachmentModalVisible);
                          setIsImage(true);
                        }}
                      />
                    );
                  } else {
                    return (
                      <div
                        className="pdf-icon-container"
                        onClick={() => {
                          setUrl(file.url);
                          setAttachmentModalVisible(!attachmentModalVisible);
                          setIsImage(false);
                        }}>
                        <img src={pdfIcon} alt="pdf" />
                      </div>
                    );
                  }
                }
              })}
            </div>
          ) : null}
        </div>
      </Modal>

      <AttachmentModal
        fileUrl={url}
        isImage={isImage}
        attachmentModalVisible={attachmentModalVisible}
        setAttachmentModalVisible={setAttachmentModalVisible}
      />
    </>
  );
};
export default IssueDetailsModal;
