export const PENDING = 2;
export const RECEIVED = 3;
export const CANCELLED = 4;

export const RUPEE = '₹';
export const DOLLAR = '$';

export const UNITS_SHORT_NAME = {
  KILOGRAMS: 'kg',
  METERS: 'm',
  CENTIMETER: 'cm',
  INCH: 'in',
  GRAMS: 'g',
  PIECES: 'Piece',
  GROSS: 'gross',
  YARDS: 'yd',
  POUNDS: 'lb',
};
export const UNIT_CONVERSION_FACTORS = {
  METER_TO_YARD: 1.09361,
  KILOGRAM_TO_POUND: 2.20462,
  YARD_TO_METER: 0.9144,
  POUND_TO_KILOGRAM: 0.453592,
};

export const baseUnitConversion = (unit) => {
  if (unit === UNITS_SHORT_NAME.YARDS) {
    return UNIT_CONVERSION_FACTORS.YARD_TO_METER;
  }
  if (unit === UNITS_SHORT_NAME.POUNDS) {
    return UNIT_CONVERSION_FACTORS.POUND_TO_KILOGRAM;
  }
  return 1;
};

export const PRODUCTION_SUB_TYPE_REVERSE_MAP = {
  1: 'Greige',
  2: 'Yarn Dyed',
  3: 'RFD',
  4: 'Dyed',
  5: 'Printed',
  6: 'Denim',
};

export const UNITS_REVERSED = {
  1: 'kg',
  2: 'm',
  3: 'cm',
  4: 'in',
  5: 'g',
  8: 'yd',
  9: 'lb',
  10: 'GSM',
  11: 'GLM',
  12: 'OZ',
};

export const LOOM_TYPE_REVERSE_MAP = {
  1: 'Power Loom',
  2: 'Sulzer',
  3: 'Air Jet',
  4: 'Water Jet',
};

export const DELIVERY_TYPE = {
  LANDED: 1,
  X_FACTORY: 2,
  CNF: 3,
};

export const LAST_MILE_DELIVERY_TYPE = {
  DEFAULT: 1,
  WAREHOUSE_SELF_PICKUP: 2,
};

export const DELIVERY_TYPE_REVERSE_MAP = {
  [DELIVERY_TYPE.LANDED]: 'Landed',
  [DELIVERY_TYPE.X_FACTORY]: 'x-Factory',
  [DELIVERY_TYPE.CNF]: 'CNF',
};

export const getQualitySpecifications = (inputs) => {
  const {
    threadcount,
    construction,
    width,
    weight,
    loomType,
    requirementType,
    denimQualtiySpecification,
    weightUnitId,
  } = inputs;
  let qualitySpecifications = '';
  if (requirementType === 6) return denimQualtiySpecification;
  if (threadcount) {
    qualitySpecifications += `${threadcount} / `;
  }

  if (construction) {
    qualitySpecifications += `${construction} / `;
  }

  if (width) {
    qualitySpecifications += `${width} inch / `;
  }

  if (weight) {
    qualitySpecifications += `${weight} ${UNITS_REVERSED[weightUnitId]} / `;
  }

  if (loomType) {
    qualitySpecifications += `${LOOM_TYPE_REVERSE_MAP[loomType]}`;
  }

  return qualitySpecifications;
};

export const BANK_TRANSFER = 7;
export const CHEQUE = 3;
export const CREDIT = 10;
export const LOCOFAST_ACCOUNT = 9;
export const PAYMENT_GATEWAY = 8;
export const UPI = 2;
export const LC = 12;
export const TT_LC = 11;
export const DEBIT = 13;
export const VPA_UPI = 6;
export const BLACK_SOIL = 20;
export const STRIDE = 25;
export const LOCOFAST = 30;

export const PAYMENT_MODE = {
  [BANK_TRANSFER]: 'Bank Transfer',
  [CHEQUE]: 'Cheque',
  [CREDIT]: 'Credit note',
  [LOCOFAST_ACCOUNT]: 'Locofast Account',
  [PAYMENT_GATEWAY]: 'Payment Gateway',
  [UPI]: 'UPI',
  [TT_LC]: 'TT + LC',
  [LC]: 'LC',
  [DEBIT]: 'Debit',
  [VPA_UPI]: 'VPA UPI',
  [BLACK_SOIL]: 'Black Soil',
  [STRIDE]: 'Stride',
  [LOCOFAST]: 'Locofast',
};

export const UNITS = {
  KILOGRAMS: 1,
  METERS: 2,
  CENTIMETER: 3,
  INCH: 4,
  GRAMS: 5,
  PIECES: 6,
  GROSS: 7,
  YARDS: 8,
  POUNDS: 9,
};

export const unitFunction = (incomingUnit, toShowUnit) => {
  switch (incomingUnit) {
    case UNITS.YARDS:
      return 'm';
    case UNITS.POUNDS:
      return 'kg';
    default:
      return toShowUnit;
  }
};

export const PRODUCT_TYPE = {
  1: 'Running quality',
  2: 'Made to order',
};

export const LENDER_ID = {
  BLACK_SOIL: 1,
  STRIDE: 2,
  LOCOFAST: 3,
};

export const LENDER_NAME_REVERSE = {
  [LENDER_ID.BLACK_SOIL]: 'Black Soil',
  [LENDER_ID.STRIDE]: 'Stride',
  [LENDER_ID.LOCOFAST]: 'Locofast',
};

export const formateDateTime = (dateString) => {
  const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };

  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', options);
};

const addressValueCheck = (word) => {
  return word !== '' && word ? `${word},` : '';
};

export const Disp = (address) => {
  if (address) {
    const { consigneeName, addressLine1, addressLine2, landmark, city, state, country, pinCode } =
      address;

    const finalValue = `${addressValueCheck(consigneeName)} ${addressValueCheck(
      addressLine1,
    )} ${addressValueCheck(addressLine2)} ${addressValueCheck(landmark)} ${addressValueCheck(
      city,
    )} ${state}-${addressValueCheck(pinCode)} ${country}`;
    return finalValue;
  }
  return '--';
};

export const orderDetails = (orderData) => {
  const { consigneeDetails, deliveryAddress } = orderData?.deliveryDetails || {};
  const [{ name: greigeSupplierManager }] = orderData?.greigeAndJobWorkQuoteDetails
    ?.greigeQuoteDetails?.supplierDetails?.supplierManagerData || [{ name: '' }];
  const [{ name: jobWorkSupplierManagers }] = orderData?.greigeAndJobWorkQuoteDetails
    ?.jobWorkQuoteDetails?.supplierDetails?.supplierManagerData || [{ name: '' }];
  const customerSidePriceConversionFactor =
    Number(orderData?.exchangeRate) /
      baseUnitConversion(orderData?.fabric?.fabricUnit?.shortName) || 1;

  return {
    customerName: orderData?.fabric?.customer?.name,
    gstIn: orderData?.fabric?.customer?.gstNumber,
    productName: orderData?.fabric?.name,
    quantity: orderData?.bookedQuantity,
    requirementType: PRODUCTION_SUB_TYPE_REVERSE_MAP[orderData?.requirementType],
    requirementTypeNumber: orderData?.requirementType,
    qualitySpecification: getQualitySpecifications(orderData),
    accountManager: orderData?.fabric?.assignedAccountManager?.name,
    isBaleAllocated: orderData?.isBaleAllocated,
    certifications: orderData?.certifications,
    deliveryType: DELIVERY_TYPE_REVERSE_MAP[orderData?.deliveryType],
    lastMileDeliveryType: orderData?.lastMileDeliveryType,
    supplierAdvancePercentage: Number(orderData?.supplierAdvancePercentage),
    jobSupplierAdvancePercentage: Number(orderData?.jobSupplierAdvancePercentage),
    isSupplierPaymentAllowed: orderData?.isSupplierPaymentAllowed,
    isJobSupplierPaymentAllowed: orderData?.isJobSupplierPaymentAllowed,
    deliveryTypeCode: orderData?.deliveryType,
    deliveryAddress: Disp(deliveryAddress),
    expectedAdvance: orderData?.paymentDetails?.amountExpected,
    amountReceived: orderData?.paymentDetails?.amountReceived,
    paymentMode: PAYMENT_MODE[orderData?.paymentDetails?.paymentMode],
    utrNumber: orderData?.paymentDetails?.referenceNumber,
    balanceAmount: orderData?.paymentDetails?.balanceAmount,
    creditPeriod: orderData?.creditPeriod,
    creditSource: orderData?.creditSource,
    discountingSource: orderData?.discountingSource,
    creditCharges: orderData?.creditCharges,
    orderQuantityTolerance: orderData?.orderQuantityTolerance,
    canEditTolerance: orderData?.canEditTolerance,
    companyRegisteredName: deliveryAddress?.companyName,
    companyGstNumber: deliveryAddress?.gstNumber,
    consigneeName: consigneeDetails?.consigneeFirstName
      ? `${consigneeDetails?.consigneeFirstName} ${consigneeDetails?.consigneeLastName || ''}`
      : null,
    mobileNumber:
      consigneeDetails?.consigneeCountryCode && consigneeDetails?.consigneePhone
        ? `${consigneeDetails?.consigneeCountryCode} ${consigneeDetails?.consigneePhone}`
        : null,
    supplierUnitPrice: orderData?.quote?.fobQuote,
    shrinkageLossPercentage: orderData?.shrinkageLossPercentage,
    shrinkageLossReason: orderData?.shrinkageLossReason,
    shrinkageLossType: orderData?.shrinkageLossType,
    supplierInvoiceAmount: orderData?.supplierInvoiceAmount,
    actualShrinkagePercentage:
      Number(orderData?.shrinkagePercentage) + Number(orderData?.shrinkageLossPercentage),
    greigeInwarded: orderData?.greigeInwarded,
    processedQuantity: orderData?.processedQuantity,
    packagingPrice: orderData?.quote?.packagingQuotePerUnit,
    jobWorkSupplierManager: jobWorkSupplierManagers,
    availabilityType: orderData?.quote?.availabilityType,
    supplierCreditPeriod: orderData?.supplierPayableDays,

    discount: orderData?.discount,
    conversionRate: orderData?.exchangeRate,
    logisticsPrice: orderData?.logisticsCost,
    sellingPrice: orderData?.quotedUnitPrice,
    margin: orderData?.margin,
    marginPercentage: orderData?.marginPercentage,
    cashMarginPercentage: orderData?.approxCashMarginPercentage,
    orderValue: orderData?.estimatedOrderValue,
    estimatedOrderValue: orderData?.estimatedOrderValueWithTax,

    advancePayment: orderData?.paymentDetails?.amountReceived,
    advancePaymentExpected: orderData?.paymentDetails?.amountExpected,
    advanceReferenceNumber: orderData?.paymentDetails?.referenceNumber,

    quotedunit: orderData?.fabric?.fabricUnit?.shortName,
    unitId: orderData?.fabric?.fabricUnit?.id,
    unit: unitFunction(orderData?.fabric?.fabricUnit?.id, orderData?.fabric?.fabricUnit?.shortName),
    currency: orderData?.fabric?.fabricCurrency?.symbol,
    orderFiles: orderData?.orderFiles,
    lcAmount: orderData?.lcDetails?.lcAmountExpected,
    lcAmountReceived: orderData?.lcDetails?.lcAmountReceived,
    lcDate: orderData?.lcDetails?.lcDate,
    lcNumber: orderData?.lcDetails?.lcNumber,
    lcBankName: orderData?.lcDetails?.bankDetails?.name,
    lcValidTill: orderData?.lcDetails?.lcValidTill,
    lcInstruc: orderData?.lcDetails?.instructions,
    isInternational: orderData?.lcDetails !== null,

    isMultipleDispatchAllowed: Boolean(orderData?.isMultipleDispatchAllowed),
    isDispatchDefined: Boolean(orderData?.isDispatchDefined),
    orderDispatchedCount: orderData?.orderDispatchedCount,
    dispatchPlan: orderData?.dispatchPlan,
    isDirectInvoicing: orderData?.isDirectInvoicing,
    // isJobWorkOrder,
    isInvoiceRequested: Boolean(orderData?.isInvoiceRequested),
    isMultipleDispatchOrder: Boolean(orderData?.isMultipleDispatchOrder),
    isBulkDispatchAllowed: Boolean(orderData?.isBulkDispatchAllowed),
    isMarkReady: Boolean(orderData?.isMarkReady),
    orderType: orderData?.orderType,
    customerSidePriceConversionFactor,

    lfbId: `LFB-${orderData?.fabric?.generatedFabricId || ''}`,
  };
};

export const orderDetailsTransformer = (orderData) => {
  const { courierPartner, tentativeDeliveryDate, quantity, additionalNotes, trackingId } =
    orderData.invoiceDetails || {};
  const { amountExpected, amountReceived, paymentMode, referenceNumber, balanceAmount } =
    orderData?.paymentDetails || {};
  const { supplierDetails, fobQuote, packagingQuote, totalSupplierUnitPrice, availabilityType } =
    orderData?.quote || {};
  const { consigneeDetails, deliveryAddress } = orderData?.deliveryDetails || {};

  return {
    customerName: orderData?.fabric?.customer?.name,
    gstIn: orderData?.fabric?.customer?.gstNumber,
    productName: orderData?.fabric?.name,
    quantity: orderData?.bookedQuantity,
    requirementType: PRODUCTION_SUB_TYPE_REVERSE_MAP[orderData?.requirementType],
    qualitySpecification: getQualitySpecifications(orderData),
    accountManager: orderData?.fabric?.assignedAccountManager?.name,
    certifications: orderData?.certifications,
    isFinalDispatch: Boolean(orderData?.isFinalDispatch),
    deliveryTypeNum: orderData?.deliveryType,
    deliveryType: DELIVERY_TYPE_REVERSE_MAP[orderData?.deliveryType],
    deliveryAddress: Disp(deliveryAddress),
    expectedAdvance: amountExpected,
    amountReceived,
    paymentMode: PAYMENT_MODE[paymentMode],
    utrNumber: referenceNumber,
    balanceAmount,
    creditPeriod: orderData?.creditPeriod,
    creditCharges: orderData?.creditCharges,
    isBaleAllocated: orderData?.isBaleAllocated,
    isSupplierBaleAllocated: orderData?.isSupplierBaleAllocated,
    fabricId: orderData?.fabricId,
    quoteId: orderData?.quote?.id,
    companyRegisteredName: deliveryAddress?.companyName,
    companyGstNumber: deliveryAddress?.gstNumber,
    consigneeName: consigneeDetails?.consigneeFirstName
      ? `${consigneeDetails?.consigneeFirstName} ${consigneeDetails?.consigneeLastName || ''}`
      : null,
    mobileNumber:
      consigneeDetails?.consigneeCountryCode && consigneeDetails?.consigneePhone
        ? `${consigneeDetails?.consigneeCountryCode} ${consigneeDetails?.consigneePhone}`
        : null,

    dispatchNumber: orderData?.dispatchNumber,
    courierPartnerName: courierPartner,
    expectedDeliveryDate: tentativeDeliveryDate,
    dispatchQuantity: quantity,
    trackingId: trackingId,
    additionalNotes,

    supplierName: supplierDetails?.companyName,
    supplierGstIn: supplierDetails?.gstNumber,
    alias: supplierDetails?.alias,
    supplierUnitPrice: fobQuote,
    jobworkQuote: orderData?.jobworkQuote,

    packagingPrice: packagingQuote,
    totalSupplierUnitPrice,
    supplierManager: orderData?.supplierManager?.userDetails?.name,
    isCertifiedSupplier: supplierDetails?.isCertified,
    availabilityType,
    supplierCreditPeriod: supplierDetails?.creditPeriod,

    discount: orderData?.discount,
    foldDiscount: orderData?.foldDiscount,
    logisticsPrice: orderData?.logisticsCost,
    sellingPrice: orderData?.quotedUnitPrice,
    margin: orderData?.margin,
    marginPercentage: orderData?.marginPercentage,
    cashMarginPercentage: orderData?.approxCashMarginPercentage,
    orderValue: orderData?.estimatedOrderValue,
    estimatedOrderValue: orderData?.estimatedOrderValueWithTax,

    unitShortName: orderData?.fabric?.fabricUnit?.shortName,
    unitId: orderData?.fabric?.fabricUnit?.id,
    quotedunit: orderData?.fabric?.fabricUnit?.shortName,
    unit: unitFunction(orderData?.fabric?.fabricUnit?.id, orderData?.fabric?.fabricUnit?.shortName),
    orderFiles: orderData?.orderFiles,

    isInternational: deliveryAddress?.isInternational,
    conversionRate: orderData?.exchangeRate,
    currency: orderData?.fabric?.fabricCurrency?.symbol,

    lfbId: `LFB-${orderData?.fabric?.generatedFabricId || ''}`,
  };
};
