import React, { useState, useEffect } from 'react';
import { get_service_endpoint } from 'ServiceEndpoints';
import './lfbIdDetails.scss';
import axios from 'axios';
import { Tooltip } from 'antd';
import rupee from 'assets/rupee.svg';
import download from 'assets/download.svg';
import certifiedTick from 'assets/CertifiedTick.svg';
import LeftChevron from './../../../../assets/images/navigation/ic-chevron-left-blue.svg';
import * as lfbDetailConstants from './constants.js';
import qs from 'query-string';
import history from 'utilites/history';

const DataField = ({
  dataLabel,
  bracketText = null,
  dataVal,
  showDataVal=true,
  isPerUnit,
  isUnit,
  isPrice,
  isPercent,
  isCreditPeriod,
  currency = '₹',
  prefix = '',
  suffix = '',
  defaultValue = '--',
  conversionRate = null,
  toShowToolTip = false,
  isCertified = false,
}) => {
  const currencyConversion = (val) => {
    return (val * conversionRate).toFixed(2);
  };
  const numWithCommas = (val, defaultVal = '') => {
    return val ? val.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',') : defaultVal;
  };
  const getValue = (val) => {
    if (isPerUnit) {
      return `${currency} ${numWithCommas(Number(val).toFixed(2))} / ${isPerUnit}`;
    }
    if (isUnit) {
      return `${val} ${isUnit}`;
    }
    if (isPrice) {
      return `${currency} ${numWithCommas(Number(val).toFixed(2))}`;
    }
    if (isPercent) {
      const percentValue = Number(val).toFixed(2);
      return `${percentValue} %`;
    }
    if (isCreditPeriod) {
      return `${val} ${val > 1 ? 'days' : 'day'}`;
    }
    if (prefix || suffix) {
      return `${prefix}${val}${suffix}`;
    }
    return val;
  };
  return (
    <div className="data-row">
      <span className="data-label">
        {dataLabel}{' '}
        {bracketText ? (
          <span>
            <br /> {bracketText}
          </span>
        ) : null}
      </span>

      <span className="data-value">
        <span>
          {toShowToolTip && (
            <div className="tool-tip">
              <Tooltip placement="top" title={`₹ ${numWithCommas(currencyConversion(dataVal))}`}>
                <img src={rupee} alt="info icon" />
              </Tooltip>
            </div>
          )}
        </span>
        {showDataVal ? (<span>{getValue(dataVal) || defaultValue}</span>) : null}
        {isCertified ? (
          <img src={certifiedTick} alt="certifiedSupplier" className="certified-icon" />
        ) : null}
      </span>
    </div>
  );
};

const DownloadPill = ({ pillName, link, containerStyle = '' }) => {
  return (
    <div>
      {link ? (
        <a href={link} target="_blank" rel="noreferrer">
          <div className="pill-container containerStyle">
            <span href={link} className="pill-name">
              {pillName}
            </span>
            <img className="pill-icon" src={download} alt="download" />
          </div>
        </a>
      ) : (
        <div>--</div>
      )}
    </div>
  );
};

const LfbIdDetails = () => {
  const orderId = JSON.parse(qs.parse(history.location.search).orderId);
  const [lfbIdData, setLfbIdData] = useState({});
  const [lfbIdDataInvoice, setLfbIdDataInvoice] = useState({});

  const foldDiscountValue = lfbIdDataInvoice?.foldDiscount
    ? `Yes (${lfbIdDataInvoice?.foldDiscount}%)`
    : 'No';

  const isCreditOrder = lfbIdData?.creditPeriod > 0;
  const creditSourceText = lfbIdData?.creditSource
    ? `(${lfbDetailConstants.LENDER_NAME_REVERSE[lfbIdData?.creditSource]})`
    : '';
  useEffect(() => {
    (async () => {
      const finance = get_service_endpoint('finance');
      const res = await axios.get(`${finance}/order/${orderId}`, {isSession: true });
      setLfbIdData(lfbDetailConstants.orderDetails(res.data.data));
      setLfbIdDataInvoice(lfbDetailConstants.orderDetailsTransformer(res.data.data));
    })();
  }, []);


 const getLinkLfbModal = (finder, arr, keyword = 'title') => {
  return arr
    ?.filter((data) => {
      return data?.fileDetail?.title === finder;
    })
    .pop();
};

 const getDownloadPIDetails = async ({ fabricId, quoteId, orderId, isSupplierBaleAllocated }) => {
    const params = {
      fabricId,
      quoteId,
      orderId,
      isSupplierBaleAllocated
    };
    try {
      const fabric = get_service_endpoint('fabric');
      const res = await axios.get(`${fabric}/quote/packaging-list`, { params, isSession: true });
      console.log("bale list pdf: ", res);
      window.open(
        res.data.data.response.fileUrl, 
        '_blank'
      );
      return res.data.data.response.fileUrl;
    } catch (Err) {
      return { ...Err.data.resp, hasError: true };
    }
  }

  const { fileDetail: { url: supplierInvoiceUrl, name: supplierInvoiceName } = {} } =
    getLinkLfbModal('Supplier Invoice', lfbIdDataInvoice?.orderFiles) || {};
  const { fileDetail: { url: podUrl, name: podName } = {} } =
    getLinkLfbModal('Proof Of Delivery', lfbIdDataInvoice?.orderFiles) || {};
  const { fileDetail: { url: customerInvoiceUrl, name: customerInvoiceName } = {} } =
    getLinkLfbModal('Customer E-Invoice', lfbIdDataInvoice?.orderFiles) || getLinkLfbModal('Customer Invoice', lfbIdDataInvoice?.orderFiles) || {};

  return (
    <div className="lfd-id-detail-container">
      <div onClick={() => {
          window.close();
        }} 
        className="header">
        {/* <span className="back-button-chevron" onClick={() => {}}>
          <img src={LeftChevron} alt="" />
        </span> */}

        <span className="customerName-lfbId">{` ${lfbIdData?.customerName}  (${lfbIdData.lfbId})`}</span>

        {lfbIdDataInvoice?.isInternational ? (
          <span className="international">{` (International Customer)`}</span>
        ) : null}
      </div>

      <div className="container mb-8">
        <div className="detailbox-inner">
          <div className="detail-box">
            <h3 className="heading">Customer order</h3>
            <DataField dataLabel={'Customer Name'} dataVal={`${lfbIdData?.customerName}`} />
            {!lfbIdData?.isInternational && (
              <DataField dataLabel={'GSTIN'} dataVal={lfbIdData?.gstIn} />
            )}
            <DataField dataLabel={'Product name'} dataVal={lfbIdData?.productName} />
            <DataField
              dataLabel={'Product type'}
              dataVal={lfbDetailConstants.PRODUCT_TYPE[lfbIdData?.availabilityType]}
            />
            <DataField
              dataLabel={'Quantity'}
              dataVal={`${lfbIdData?.quantity} ${lfbIdData?.quotedunit}`}
            />
            <DataField dataLabel={'Requirement type'} dataVal={lfbIdData?.requirementType} />
            <DataField
              dataLabel={'Quality specifications'}
              dataVal={lfbIdData?.qualitySpecification}
            />
            <DataField dataLabel={'Account manager'} dataVal={lfbIdData?.accountManager} />
          </div>

          <div className="detail-box">
            {/* <Card heading={"Customer order"}/> */}
            <h3 className="heading">Delivery details</h3>
            <DataField
              dataLabel={'Courier partner'}
              dataVal={lfbIdDataInvoice?.courierPartnerName}
            />
            <DataField
              dataLabel={'Dispatch'}
              dataVal={
                Boolean(lfbIdDataInvoice?.dispatchNumber)
                  ? `D${lfbIdDataInvoice?.dispatchNumber}`
                  : '--'
              }
            />
            <DataField dataLabel={'Docket number'} dataVal={lfbIdDataInvoice?.trackingId} />
            <div className="download-box">
              <DataField dataLabel="Proof of Delivery" dataVal={''} showDataVal={false} />
              <DownloadPill
                containerStyle="pill-style"
                pillName={'POD.pdf'}
                link={podUrl}
              />
            </div>
            <DataField
              dataLabel={'Delivered quantity'}
              dataVal={`${lfbIdDataInvoice?.dispatchQuantity} ${
                lfbIdDataInvoice?.isInternational ? 'yd' : 'm'
              }`}
            />
            {lfbIdDataInvoice?.isBaleAllocated || lfbIdDataInvoice?.isSupplierBaleAllocated ? (
              <div className={'download-box'}>
                <DataField dataLabel="Bale details"  showDataVal={false} />
                <div
                  onClick={() =>
                    getDownloadPIDetails({
                      quoteId: lfbIdDataInvoice?.quoteId,
                      fabricId: lfbIdDataInvoice?.fabricId,
                      orderId,
                      ...(lfbIdDataInvoice?.isSupplierBaleAllocated && {
                        isSupplierBaleAllocated: lfbIdDataInvoice?.isSupplierBaleAllocated
                      })
                    })
                  }
                  >
                  <div className='pill-container' style={{ marginTop: 12 }}>
                    <span className='pill-name'>Bales.pdf</span>
                    <img className='pill-icon' src={download} alt="download" />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="download-box">
              <DataField dataLabel="Customer Invoice" dataVal={''} showDataVal={false} />
              <DownloadPill
                containerStyle="pill-style"
                pillName={'Invoice.pdf'}
                link={customerInvoiceUrl}
              />
            </div>
            <DataField
              dataLabel={'Company registered name'}
              dataVal={lfbIdData?.companyRegisteredName}
            />
            <DataField dataLabel={'Company GST number'} dataVal={lfbIdData?.companyGstNumber} />
            <DataField dataLabel={'Delivery Address'} dataVal={lfbIdData?.deliveryAddress} />
            <DataField dataLabel={'Consignee name'} dataVal={lfbIdData?.consigneeName} />
            <DataField dataLabel={'Mobile number'} dataVal={lfbIdData?.mobileNumber} />
          </div>
        </div>
        <div className="detailbox-inner">
          <div className="detail-box">
            {/* <Card heading={"Customer order"}/> */}
            <h3 className="heading">Supplier details</h3>

            <DataField
              dataLabel={`Supplier Name ${
                !lfbIdDataInvoice?.jobworkQuote ? '' : ' (Greige Supplier)'
              }`}
              dataVal={lfbIdDataInvoice?.supplierName}
              alias={lfbIdDataInvoice.alias}
              isCertified={lfbIdDataInvoice?.isCertifiedSupplier}
            />

            {lfbIdDataInvoice?.jobworkQuote ? (
              <DataField
                dataLabel="Supplier Name (Processing Supplier)"
                dataVal={lfbIdDataInvoice.jobworkQuote?.supplierDetails?.companyName}
                alias={lfbIdDataInvoice.jobworkQuote?.supplierDetails?.alias}
                isCertified={lfbIdDataInvoice?.isCertifiedSupplier}
              />
            ) : null}

            <DataField dataLabel="Supplier GSTIN" dataVal={lfbIdDataInvoice?.supplierGstIn} />
            <div className="download-box">
              <DataField dataLabel="Supplier Invoice" dataVal={''} showDataVal={false} />
              <DownloadPill
                containerStyle="pill-style"
                pillName={'Invoice.pdf'}
                link={supplierInvoiceUrl}
              />
            </div>

            <DataField
              dataLabel="Supplier selling price"
              dataVal={lfbIdDataInvoice?.totalSupplierUnitPrice}
              isPrice={true}
              isPerUnit={lfbIdDataInvoice?.unit}
            />

            <DataField
              dataLabel={`Supplier Manager ${
                !lfbIdDataInvoice?.jobworkQuote ? '' : ' (Greige Supplier)'
              }`}
              dataVal={lfbIdDataInvoice?.supplierManager}
            />

            {lfbIdDataInvoice?.jobworkQuote ? (
              <DataField
                dataLabel="Supplier Manager (Processing Supplier)"
                dataVal={lfbIdData.jobWorkSupplierManager}
              />
            ) : null}
          </div>

          <div className="detail-box">
            <h3 className="heading">Amount details</h3>
            <DataField dataLabel={'Fold Discount'} dataVal={foldDiscountValue} />
            <DataField dataLabel={'Discount'} dataVal={lfbIdDataInvoice?.discount} />

            {!!lfbIdDataInvoice?.isInternational && (
              <DataField
                dataLabel="Conversion Rate"
                dataVal={lfbIdDataInvoice?.conversionRate}
                isPrice
              />
            )}

            {!(
              lfbIdDataInvoice?.deliveryTypeNum === lfbDetailConstants.DELIVERY_TYPE.X_FACTORY
            ) && (
              <DataField
                dataLabel={lfbIdDataInvoice?.isInternational ? 'CNF cost' : 'Logistics Price'}
                dataVal={lfbIdDataInvoice?.logisticsPrice}
                isPerUnit={lfbIdDataInvoice?.quotedunit}
                currency={lfbIdDataInvoice?.currency}
                conversionRate={lfbIdDataInvoice?.conversionRate}
                toShowToolTip={lfbIdData.currency !== lfbDetailConstants.RUPEE}
              />
            )}
            <DataField
              dataLabel={'Selling Price'}
              dataVal={lfbIdDataInvoice?.sellingPrice}
              isPerUnit={lfbIdDataInvoice?.quotedunit}
              currency={lfbIdDataInvoice?.currency}
              conversionRate={lfbIdDataInvoice?.conversionRate}
              toShowToolTip={lfbIdData.currency !== lfbDetailConstants.RUPEE}
            />
            <DataField
              dataLabel={`Margin`}
              bracketText={'(Inclusive of credit charges)'}
              dataVal={lfbIdDataInvoice?.margin}
              currency={lfbIdDataInvoice?.currency}
              conversionRate={lfbIdDataInvoice?.conversionRate}
              toShowToolTip={lfbIdData.currency !== lfbDetailConstants.RUPEE}
              isPrice
            />
            <DataField dataLabel={'Margin %'} dataVal={`${lfbIdDataInvoice?.marginPercentage} %`} />
            <DataField
              dataLabel={'Cash margin % (approx)'}
              dataVal={`${lfbIdDataInvoice?.cashMarginPercentage} %`}
            />
            <DataField
              dataLabel={'Order Value (exclusive of taxes)'}
              dataVal={lfbIdDataInvoice?.orderValue}
              currency={lfbIdDataInvoice?.currency}
              conversionRate={lfbIdDataInvoice?.conversionRate}
              toShowToolTip={lfbIdData.currency !== lfbDetailConstants.RUPEE}
              isPrice
            />
            <DataField
              dataLabel={'Estimated Order Value'}
              bracketText={'(inclusive of taxes)'}
              dataVal={lfbIdDataInvoice?.estimatedOrderValue}
              currency={lfbIdDataInvoice?.currency}
              conversionRate={lfbIdDataInvoice?.conversionRate}
              toShowToolTip={lfbIdData.currency !== lfbDetailConstants.RUPEE}
              isPrice
            />

            <DataField dataLabel={'Order type'} dataVal={isCreditOrder ? `Credit` : 'Cash'} />
            {isCreditOrder && (
              <>
                <DataField
                  dataLabel="Credit period"
                  dataVal={lfbIdDataInvoice?.creditPeriod}
                  isCreditPeriod
                />
                <DataField
                  dataLabel="Credit charges"
                  dataVal={`${lfbIdDataInvoice?.creditCharges} %`}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LfbIdDetails;
