import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
  activeIssueData: [],
  historyIssueData: [],
  paginationData: {},
};

const IssuePanelReducer = handleActions(
  {
    GET_ACTIVE_ISSUE_DATA: (state, action) => {
      return {
        ...state,
        activeIssueData: action.payload,
      };
    },
    GET_PAGINATION_DATA: (state, action) => {
      return {
        ...state,
        paginationData: action.payload,
      };
    },
    GET_HISTORY_ISSUE_DATA: (state, action) => {
      return {
        ...state,
        historyIssueData: action.payload,
      };
    },
  },
  INITIAL_STATE,
);

export default IssuePanelReducer;
