import React from 'react';
import { Modal } from 'antd';
import './AttachmentModal.scss';
import documentIcon from 'assets/document.svg';
import downloadIcon from 'assets/download.svg';

const PdfAttachment = ({ fileUrl }) => {
  return (
    <div className="pdf-attachment">
      <img src={documentIcon} alt="PDF Icon" className="pdf-image" />
      <a href={fileUrl} target="_blank" rel="noopener noreferrer" className="download-link">
        <span className="download-text">Download</span>
        <span>
          <img src={downloadIcon} alt="PDF Icon" className="download-image" />
        </span>
      </a>
    </div>
  );
};

const AttachmentModal = ({
  fileUrl,
  isImage,
  attachmentModalVisible,
  setAttachmentModalVisible,
}) => {
  const handleCancel = () => {
    setAttachmentModalVisible(false);
  };

  return (
    <Modal
      title="Image Preview"
      visible={attachmentModalVisible}
      onCancel={handleCancel}
      footer={null}
      className="attachment-modal">
      {isImage ? (
        <img
          src={fileUrl}
          alt="Modal Image"
          className="modal-image"
        //   style={{ width: '100%', height: 'auto' }}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <PdfAttachment fileUrl={fileUrl} />
      )}
    </Modal>
  );
};

export default AttachmentModal;
